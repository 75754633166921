
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import VqButton from "@/components/widgets/VqButton/VqButton.vue";

@Options({
  components: { Top, VqInput, VqButton },
})
export default class Register extends Vue {
  private get getCaptchaText(): string {
    // 获取验证码|52s|重新获取
    return "获取验证码";
  }
}
