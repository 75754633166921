
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class VqButton extends Vue {
  @Prop(Boolean) private disable!: boolean;
  @Prop(Object) private to!: any;
  @Prop({ type: String, default: "normal" }) private size!: "normal" | "small";

  private get classObj(): any {
    return {
      VqButton_disabled: this.disable,
      VqButton_normal: this.size === "normal",
      VqButton_small: this.size === "small",
    };
  }

  private handleClicked(): void {
    if (this.to) {
      this.$router.push(this.to);
    }
  }
}
