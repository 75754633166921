
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import VqButton from "@/components/widgets/VqButton/VqButton.vue";

@Options({
  components: { Top, VqInput, VqButton },
})
export default class Login extends Vue {}
